<template>
  <div>
    <vm-table ref="vmTable" v-loading="loading" :filter.sync="search" url="tenant">
      <template slot="adSearch">
        <vm-search :label="$l('maintWorkOrder.filter', '关键字检索')">
          <el-input v-model.trim="search.filter" :placeholder="$t('common.search')" style="width: 150px"
            clearable></el-input>
        </vm-search>
        <vm-search :label="$l('contract.contractSource', '租户状态')">
          <el-select v-model="search.status" :placeholder="$t('common.pleaseSelect')" style="width: 120px" clearable>
            <el-option v-for="item in statusList" :key="item.value" :label="item.label" :value="item.value"></el-option>
          </el-select>
        </vm-search>
      </template>
      <template slot="toolbar">
        <el-button class="addBtn" @click="$refs.editPage.open(0)">
          {{ $l("elevatorNetApply.add", "+ 新增") }}
        </el-button>
      </template>
      <el-table-column prop="name" label="租户名称" show-overflow-tooltip></el-table-column>

      <el-table-column prop="status" label="租户状态" align="center" width="100">
        <template v-slot="scope">
          <el-tag type="danger" v-if="scope.row.status === 1">
            冻结
          </el-tag>
          <el-tag type="success" v-if="scope.row.status === 0">
            正常
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="companyName" label="初始公司" show-overflow-tooltip></el-table-column>
      <el-table-column prop="elevCount" :label="$t('company.elevCount')" align="center" width="80"></el-table-column>
      <el-table-column prop="userCount" :label="$t('company.userCount')" align="center" width="80"></el-table-column>
      <el-table-column prop="username" label="用户名" width="140" align="center"></el-table-column>
      <el-table-column prop="contact" label="联系人" width="140" align="center"></el-table-column>
      <el-table-column prop="phone" label="手机号码" align="center" width="120"></el-table-column>
      <el-table-column prop="createTime" :label="$l('contract.contractEndDate', '创建时间')" align="center"
        width="140"></el-table-column>
      <el-table-column v-if="false" prop="updateTime" :label="$l('contract.contractPrice', '更新时间')"
        align="center"></el-table-column>
      <el-table-column :label="$l('elevatorNetApply.operate', '操作')" align="center" width="150">
        <template v-slot="scope">
          <el-button type="text" @click="login(scope.row)">
            登录
          </el-button>
          <el-button type="text" @click="$refs.editPage.open(scope.row.id)">
            编辑
          </el-button>
          <el-button v-if="false" type="text" @click="handleDelete(scope.row)">删除</el-button>
          <el-button type="text" @click="changePwd(scope.row.username)">修改密码</el-button>
        </template>
      </el-table-column>
    </vm-table>
    <tenant-edit ref="editPage" @save-success="getList(-1)"></tenant-edit>
    <tenant-change-pwd ref="changePwd"></tenant-change-pwd>
  </div>
</template>

<script>
import TenantEdit from "@/views/tenant/TenantEdit";
import TenantChangePwd from "@/views/tenant/TenantChangePwd";
import auth from "@/util/auth";

export default {
  components: { TenantEdit, TenantChangePwd },
  data() {
    return {
      loading: true,
      search: {
        filter: "",
        status: "",
      },
      statusList: [
        { value: 1, label: "冻结" },
        { value: 0, label: "正常" },
      ],
    };
  },
  mounted() {
    this.getList(1);
  },
  methods: {
    getList(pageNum) {
      this.loading = false;
      this.$refs.vmTable.getList(pageNum);
    },
    handleDelete(row) {
      this.$confirm("确定删除吗?", this.$t("common.tips"), { type: "warning" }).then(() => {
        this.$http.delete("tenant", row.id).then(() => {
          this.getList(-1);
          this.$message.success(this.$t("common.tip.deleteSuccess"));
        });
      });
    },
    changePwd(username) {
      this.$refs.changePwd.open(username);
    },
    login(row) {
      let params = {
        username: row.username,
        password: row.password,
      };
      this.$api.getData("account/tenantLogin", params).then((res) => {
        auth.logout();
        auth.login(res.data);
        window.location.href = "/";
      }).catch(error => {
        this.$message.error("登陆失败");
      });
    },
  },
};
</script>

<style scoped></style>
