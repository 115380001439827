<template>
  <el-dialog
    :close-on-click-modal="false"
    :visible.sync="dialogVisible"
    title="更改密码"
    width="460px"
    @close="dialogClose">
    <el-form
      ref="form"
      class="changePwdForm"
      :model="changePwd"
      size="small"
      :rules="ruleValidate">
      <el-form-item prop="username">
        <el-input v-model="changePwd.username" disabled>
          <template slot="prepend">账号</template>
        </el-input>
      </el-form-item>
      <el-form-item prop="newPassword">
        <el-input
          v-model="changePwd.newPassword"
          placeholder="请输入"
          type="password"
          show-password
          clearable>
          <template slot="prepend">新密码</template>
        </el-input>
      </el-form-item>
      <el-form-item prop="confirmNewPassword">
        <el-input
          v-model="changePwd.confirmNewPassword"
          placeholder="请输入"
          type="password"
          show-password
          clearable>
          <template slot="prepend">确认新密码</template>
        </el-input>
      </el-form-item>
      <el-button
        :loading="submitLoading"
        style="width: 100%;margin-top: 10px"
        type="primary"
        size="medium"
        @click="submit">
        提交
      </el-button>
    </el-form>
  </el-dialog>
</template>

<script>
  export default {
    data() {
      return {
        dialogVisible: false,
        submitLoading: false,
        changePwd: {
          username: "",
          newPassword: "",
          confirmNewPassword: "",
        },
      };
    },
    computed: {
      ruleValidate() {
        return {
          newPassword: {
            required: true,
            pattern: /^(?![A-Za-z]+$)(?![A-Z\d]+$)(?![A-Z\W]+$)(?![a-z\d]+$)(?![a-z\W]+$)(?![\d\W]+$)\S{8,20}$/,
            message: this.$l("user.tip.password", "8~30位字符，必须包含大写字母、小写字母、数字"),
            trigger: "blur",
          },
        };
      },
    },
    methods: {
      open(username) {
        this.changePwd.username = username;
        this.dialogVisible = true;
      },
      dialogClose() {
        this.$refs.form.resetFields();
      },
      submit() {
        this.$refs.form.validate(valid => {
          if (valid) {
            if (this.changePwd.newPassword !== this.changePwd.confirmNewPassword) {
              this.$message.error("两次输入的新密码不一致");
              return;
            }
            this.submitLoading = true;
            this.$api.getData("account/tenantPassword", this.changePwd)
              .then(() => {
                this.submitLoading = false;
                this.dialogVisible = false;
                this.$message.success("密码修改成功");
              })
              .catch(() => {
                this.submitLoading = false;
              });
          }
        });
      },
    },
  };
</script>
<style lang="scss">
.changePwdForm {
  .el-input-group__prepend {
    width: 72px;
    text-align: center;
    padding: 0 5px;
  }
}
</style>
