<template>
  <div>
    <vm-table ref="vmTable" v-loading="loading" :filter.sync="search" multi-selection="true" url="companies"
      @selection-change="handleSelectionChange">
      <template slot="adSearch">
        <vm-search :label="$l('maintWorkOrder.filter', '关键字检索')">
          <el-input v-model.trim="search.filter" :placeholder="$t('common.search')" clearable></el-input>
        </vm-search>
        <vm-search :label="$l('company.type', '企业类型')">
          <el-select v-model.trim="search.type" :placeholder="$t('common.pleaseSelect')" style="width: 100%" clearable>
            <el-option v-for="item in companyTypeList" :key="item.value" :label="item.label"
              :value="item.value"></el-option>
          </el-select>
        </vm-search>
        <vm-search label="所属单位">
          <el-input v-model.trim="search.orgName" clearable></el-input>
        </vm-search>
        <vm-search label="所属租户">
          <el-input v-model.trim="search.tenantName" clearable></el-input>
        </vm-search>
      </template>
      <template slot="toolbar">
        <el-button @click="$refs.setCompany.open()" @save-success="getList(-1)">
          所属单位
        </el-button>
        <el-button @click="$refs.setTenant.open()" @save-success="getList(-1)">
          所属租户
        </el-button>
      </template>
      <el-table-column prop="tenantName" label="租户" show-overflow-tooltip></el-table-column>
      <el-table-column prop="orgName" label="所属单位" show-overflow-tooltip></el-table-column>
      <el-table-column prop="elevCount" :label="$t('company.elevCount')" align="center"
        width="100"></el-table-column>
      <el-table-column prop="userCount" :label="$t('company.userCount')" align="center"
        width="100"></el-table-column>
      <el-table-column prop="name" :label="$t('company.name')" min-width="120" show-overflow-tooltip></el-table-column>
      <el-table-column prop="no" :label="$t('company.no')" width="120" align="center"></el-table-column>
      <el-table-column :label="$t('company.type')" :width="$l('company.width', '100')" align="center">
        <template slot-scope="scope">
          {{ getTypeName(scope.row.type) }}
        </template>
      </el-table-column>
      <el-table-column prop="creator" label="创建人" width="120" show-overflow-tooltip></el-table-column>
      <el-table-column prop="createTime" label="创建时间" width="140" align="center"></el-table-column>
      <el-table-column fixed="right" :label="$t('common.operation')" width="80" align="center">
        <template slot-scope="scope">
          <el-button type="text" class="operateDelBtn" @click="handleDelete(scope.row)">{{ $t("common.delete") }}</el-button>
        </template>
      </el-table-column>
    </vm-table>
    <set-company :multi-select="multiSelect" action-type="company" ref="setCompany"
      @save-success="getList(-1)"></set-company>
    <set-tenant :multi-select="multiSelect" action-type="company" ref="setTenant"
      @save-success="getList(-1)"></set-tenant>
  </div>
</template>
<script>
import SetTenant from "@/views/tenant/SetTenant";
import SetCompany from "@/views/tenant/SetCompany";
import { companyType, companyType_en } from "../../util/constant";

const moduleName = "companies";

export default {
  components: { SetCompany, SetTenant },
  data() {
    return {
      loading: false,
      multiSelect: [],
      search: {
        filter: "",
        type: "",
        orgName: "",
        tenantName: "",
      },
      companyTypeList: [
        { value: 1, label: this.$l("elevatorNetApply.createCom", "制造企业") },
        { value: 2, label: this.$l("elevatorNetApply.maintComName", "维保企业") },
        { value: 3, label: this.$l("elevator.installationEnterprise", "安装企业") },
        { value: 5, label: this.$l("maintWorkOrder.propertyCom", "物业公司") },
        { value: 8, label: this.$l("elevator.useUnit", "使用单位") },
        { value: 6, label: this.$l("company.renovationCompany", "改造单位") },
      ],
    };
  },
  mounted() {
    this.getList(1);
  },
  methods: {
    getList(pageNum) {
      this.$refs.vmTable.getList(pageNum);
    },
    handleSelectionChange(val) {
      this.multiSelect = val;
    },
    handleDelete(row) {
      this.$confirm(this.$t("common.delete") + " " + this.$t("company.no") + " " + row.no + "，" + this.$t("common.isDelete") + "?", this.$t("common.tips"), {
        confirmButtonText: this.$t("common.confirm"),
        cancelButtonText: this.$t("common.cancel"),
        type: "warning",
      }).then(() => {
        this.$api.deleteById("companies", row.id).then(res => {
          this.getList(-1);
          this.$message.success(this.$t("common.tip.deleteSuccess"));
        }).catch(() => {
          this.$message.error(this.$t("common.tip.deleteError"));
        });
      });
    },
    getTypeName(type) {
      if (this.$i18n.isCn) {
        return companyType[type];
      } else {
        return companyType_en[type];
      }
    },
  },
};
</script>
<style lang="scss" scoped></style>
