<template>
  <div>
    <div>
      <el-tabs v-model="activeName" tab-position="top">
        <el-tab-pane label="电梯档案" name="first">
          <elevator-list></elevator-list>
        </el-tab-pane>
        <el-tab-pane label="单位信息" name="second">
          <company-list></company-list>
        </el-tab-pane>
        <el-tab-pane label="用户" name="forth">
          <user-list></user-list>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
  import ElevatorList from "@/views/tenant/ElevatorList";
  import CompanyList from "@/views/tenant/CompanyList";
  import UserList from "@/views/tenant/UserList";
  import auth from "../../util/auth";

  export default {
    components: {UserList, CompanyList, ElevatorList},
    data() {
      return {
        activeName: "first",
        showOrgId:  auth.getUsername() === "superAdmin",
        submitLoadingOrg: false,
        type: 1,
        elevatorList: [],
        orgId: {
          companyId: 0,
          companyName: "",
        },
        tenant: {
          tenantId: 0,
          tenantName: "",
        },
      };
    },
    mounted() {

    },
    methods: {
      clearOrg() {
        this.orgId = {
          companyId: 0,
          companyName: "",
        };
        this.tenant = {
          tenantId: 0,
          tenantName: "",
        };
      },
    },
  };
</script>

<style scoped>
</style>
